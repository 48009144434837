@import "@styles/main.scss";
.root {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: calc(($_category-card-max-width * $_category-card-aspect-ratio) / 2);
  transform: translateY(-50%);
  height: $_carousel-arrow-width;

  @include media-tablet {
    display: none;
  }
}
