@import "@styles/main.scss";
.root {
  display: flex;
  align-items: center;
  color: $color-primary-redesign;
  flex-flow: row wrap;

  @include media-tablet {
    span {
      margin-top: $gap4;
      margin-bottom: $gap4;

      &.wrap {
        margin-right: 100%;
        margin-top: 0;
      }
    }

    flex-flow: row wrap;
  }
}

.code {
  padding: $gap4;
  margin-right: $gap16;
  border: 1px solid rgba($color-primary-redesign, 0.3);
  border-radius: $_border-radius-small;
  margin-bottom: $gap2;
  margin-top: $gap2;
}

.item {
  display: flex;
  align-items: center;
  margin-right: $gap16;
  white-space: nowrap;
  margin-bottom: $gap4;
  margin-top: $gap4;

  * {
    vertical-align: bottom;
  }

  &:last-child {
    padding-right: 0;
    margin-right: 0;
  }

  svg {
    margin-right: $gap4;
  }

  @include media-mobile {
    white-space: break-spaces;
  }
}
