@import "@styles/main.scss";
.root {
  display: grid;
  column-gap: $gap12;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-content: flex-start;

  @include media-mobile {
    column-gap: $gap8;
  }
}

.socialsLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid $color-secondary-redesign;
  border-radius: 80px;
  cursor: pointer;
  transition: background-color $transitionTime;

  svg {
    width: 20px;
    height: 20px;
  }

  @include hover {
    background-color: $color-primary-redesign;
  }
}
