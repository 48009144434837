@import "@styles/main.scss";
.root {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $color-overlay;
  overflow-y: auto;
  z-index: 10;

  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  // TODO SZ: implement removing component from DOM with saving animations instead of using "translate"
  transform: translateX(110vw);

  &.open {
    transform: translateX(0);
  }
}

.content {
  z-index: 20;
}
