@import "@styles/main.scss";
@import './src/styles/variables';
@import './src/styles/mixins';

.search {
  position: relative;
  display: flex;
  align-items: center;
  transition: border-color $transitionTime;

  > svg {
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    margin-right: $gap16;
  }

  & .clearBtn {
    margin-right: $gap16;
    z-index: 1;
    width: 10px;
    height: 10px;
    transition: color $transitionTime;

    &:hover {
      color: $color-gray;
    }
  }

  & div {
    width: 100%;
  }

  & .searchInput {
    padding-left: $gap8;
    padding-right: $gap8;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.02em;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    width: 100%;
    color: $color-primary-redesign;

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      padding-left: $gap8;
      padding-right: $gap8;
    }

    @include media-tablet {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @include media-tablet {
    padding: 0 $gap24;
    border: 2px solid transparent;
    border-radius: $_border-radius-round;
    background-color: $color-secondary-redesign;

    &:focus-within {
      border: 2px solid $color-primary-redesign;
      background-color: $color-white;
    }

    & .clearBtn {
      margin-right: 0;
    }

    svg:not(.clearBtn svg) {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      margin-right: $gap8;
    }
  }
}

.selectedAmount {
  white-space: nowrap;
}

.loader {
  height: 100%;
}

.list {
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  overflow: hidden;
  background-color: $color-white;
  max-height: 1000px;

  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border: 1px solid $color-secondary-redesign;
  box-shadow: $box-shadow-wide;
  border-radius: $_border-radius-medium;

  padding: $gap24;

  &.active {
    z-index: 1;
  }
}

.value {
  width: 100%;
  border: 1px solid $color-input-border;
  border-radius: 16px;
  padding: $gap16 $gap24;
  padding-bottom: $gap4;
  height: 52px;
  transition: border-color $transitionTime;
  padding-right: $gap40;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(.disabled) {
    &:hover {
      border-color: $color-input-border-focus;
    }

    &.error {
    }
  }

  &.active {
    border-color: $color-input-border-focus;
  }
}

.option {
  background-color: $color-white;
  cursor: pointer;
  transition: color 0.15;
}

.divider {
  margin-top: $gap16;
  margin-bottom: $gap16;

  @include media-tablet {
    margin-top: $gap8;
    margin-bottom: $gap24;
  }
}

.allResearches {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: $gap8;

  color: $color-primary-light-redesign;
}

.rightArrowIcon {
  padding-top: $gap2;
}
