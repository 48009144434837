@import "@styles/main.scss";
.toast {
  margin-bottom: $gap8;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.toastBody {
  display: flex;
  align-items: center;
  padding: $gap8 $gap12;
  font-family: 'Graphik';
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.success {
  background-color: $color-primary-redesign;
}

.error {
  background-color: $color-tonys-pink;
}
