@import "@styles/main.scss";
.root {
  padding: $gap24;
  border: 1px solid $color-input-border;
  margin-bottom: $gap12;

  @include media-tablet {
    padding: $gap12;
    border-radius: $_border-radius-common
  }
}

.cardWrapper {
  margin-top: $gap24;
  margin-bottom: $gap48;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $gap32;
  border: 1px solid $color-primary-redesign;
  border-radius: $_border-radius-common;

  @include media-tablet {
    margin-top: $gap12;
    margin-bottom: $gap24;
    padding: $gap24;
  }
}

.cardTitle {
  padding-bottom: $gap24;
  color: $step-card-title-dark-color;
}

.cardContent {
  align-items: center;
  padding-top: $gap24;
  border-top: 1px solid $color-secondary-redesign;
  width: 100%;
}

.contacts {
  width: 50%;
}

.orderLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content {
  margin-bottom: $gap20;
}

.currentLab {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: $gap16;

  &:last-child {
    margin-bottom: $gap16;;
  }
}

.cardItemTitle {
  opacity: 1;
  order: 0;
  flex-grow: 1;
  margin-right: $gap24;
  color: $step-card-title-dark-color;

  @include media-tablet {
    @include type-button-small;
  }
}

.light {
   opacity: 0.4;
}

.cardItemValue {
  order: 1;
  flex-grow: 0;
  color: $step-card-title-dark-color;

  @include media-tablet {
    @include type-button-small;
  }
}

.productItem {
  margin-bottom: $gap20;
  padding-top: $gap20;
  border-top: 1px solid $color-secondary-redesign;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.cardItemTitleLabel {
  @include type-caption-small;
  color: $color-primary-dark-redesign;
  padding-top: $gap4;
  margin-bottom: $gap24;
  order: 1;
  flex-grow: 0;
}

.cardItemValueLabel {
  margin-bottom: $gap24;
}

.marginTop24 {
  margin-top: $gap24;
}

.prevStepButton {
  margin-top: $gap16;
}

.payhomeButton {
  margin-top: $gap16;
}

.loader {
  height: 100%;
}
