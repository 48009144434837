@import "@styles/main.scss";
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-brand;

  & > svg {
    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}