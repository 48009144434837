@import "@styles/main.scss";
.stepWrapper {
  margin-top: $gap72;
  margin-bottom: $gap72;

  @include media-tablet {
    margin-top: $gap40;
    margin-bottom: $gap56;
  }
}

.addressSuggestions {
  margin-top: $gap20;
}

.partWrapper {
  margin-top: $gap42;
  margin-bottom: $gap72;

  @include media-tablet {
    margin-top: $gap32;
    margin-bottom: $gap32;
  }

  &.last {
    margin-bottom: $gap42;

    @include media-tablet {
      margin-bottom: $gap24;
    }
  }
}

.inputRow {
  margin-top: $gap42;

  &:not(:last-child) {
    margin-bottom: $gap16;
  }

  @include media-tablet {
    margin-top: $gap24;

    &:not(:last-child) {
      margin-bottom: $gap16;
    }
  }

  .summWrapper & {
    margin: $gap16 0;

    &:not(:last-child) {
      margin-bottom: $gap16;
    }

    @include media-tablet {
      margin: $gap16 0;

      &:not(:last-child) {
        margin-bottom: $gap16;
      }
    }
  }
}

.map {
  height: 296px;
  margin-top: $gap24;
  border-radius: $_border-radius-medium;
  overflow: hidden;
}

.mapTitle {
  margin-top: $gap24;
  text-align: center;
}

.checkboxRow {
  &:not(:last-child) {
    margin-bottom: $gap14;
  }
}

.inputHalfRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > div {
    flex-basis: calc(50% - 8px);
    flex-shrink: 0;
  }

  @include media-mobile {
    flex-wrap: wrap;

    & > div {
      flex-basis: 100%;

      &:not(:first-child) {
        margin-top: $gap16;
      }
    }
  }
}

.inputOneThirdRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex-basis: calc((100% - $gap16 * 2) / 3);
    flex-shrink: 0;
  }

  @include media-tablet {
    & > div {
      flex-wrap: wrap;
      flex-basis: calc((100% - $gap8 * 2) / 3);
    }
  }
}

.stepButton {
  margin-bottom: $gap32;
}

.info {
  display: flex;
  margin-top: $gap24;
  margin-bottom: $gap32;
  background-color: $color-secondary-redesign;
  border-radius: $_border-radius-common;

  & > svg {
    flex-shrink: 0;
    margin-right: $gap12;
  }
}

.checkboxBlock {
  margin-top: $gap24;
}

.link {
  color: $main-link-color;
  cursor: pointer;
  white-space: nowrap;

  @include hover() {
    color: $main-link-hover-color;
  }
}

.paymentForm {
  padding-top: $gap40;
}

.errorText {
  color: $color-error;
  text-align: center;
  margin-top: $gap8;
  font-weight: 700;
}

.checkBoxText {
  @include media-mobile {
    font-size: 14px;
    line-height: 16px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: $gap16;
}

.textContent {
  padding-left: $gap16;
  display: inline-block;
}

.iconProtectContainer {
  width: 18px;
}

.prevStepButton {
  margin-top: $gap16;
}

.birthdayCalendarPopper {
  width: $_birthday-calendar-width;
  max-width: $_birthday-calendar-max-width;
  z-index: 10;

  @include media-mobile {
    max-width: $_birthday-calendar-max-width-mobile;
  }
}
