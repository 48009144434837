@import "@styles/main.scss";
@import './fonts';
@import './constants';
@import './variables';
@import './mixins';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  scrollbar-color: rgba(0, 0, 0, 0.25);
  scrollbar-width: thin;
  width: 100%;
}

body {
  @include typo-font-Platform;
  line-height: 1;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#__next {
  min-height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.overflow-hidden {
  overflow: hidden;
  display: none;
}

.container {
  padding-left: $desktop-container-gap;
  padding-right: $desktop-container-gap;
  margin: 0 auto;

  // TODO SZ: 1) perhaps move to mixin 2) make a variable for screen width
  @media (max-width: 1400px) {
    padding: 0 $gap72;
  }

  @media (max-width: 700px) {
    padding: 0 $mobile-container-gap;
  }
}

.container-small {
  max-width: $container-small-width + $mobile-container-gap * 2;
  padding: 0 $mobile-container-gap;
  margin: 0 auto;

  @include media-tablet() {
    padding: 0;
  }
}

.container-medium {
  max-width: $container-medium-width + $mobile-container-gap * 2;
  padding: 0;
  margin: 0 auto;

  @include media-tablet() {
    padding: 0;
  }
}

.container-wide {
  max-width: $desktop-max-width; //1280px
  margin: 0 auto;
}

.container-full-width {
  padding-left: $desktop-container-gap;
  padding-right: $desktop-container-gap;
  margin-left: -$desktop-container-gap;
  margin-right: -$desktop-container-gap;

  @include media-tablet() {
    padding-left: $mobile-container-gap;
    padding-right: $mobile-container-gap;
    margin-left: -$mobile-container-gap;
    margin-right: -$mobile-container-gap;
  }
}

.max-width-mobile {
  max-width: $container-small-width + $mobile-container-gap * 2;
  margin: 0 auto;
}

.popup-content {
  background: $primary-bg-color;
  max-width: $container-small-width;
  padding: $gap48;
  width: 100%;
  box-shadow: 0 12px 32px -16px rgba(53, 53, 53, 0.2);
  border-radius: 24px;
  animation: popup 0.1s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  //margin-top: 18px !important;

  @include media-tablet {
    padding: $gap48 $gap12 $gap24;
    box-shadow: none;
    margin-top: 32px !important;
  }

  @include media-mobile {
    margin-top: 18px !important;
  }
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100%;
  padding: 24px;
  overflow-y: auto;

  @include media-tablet {
    padding: 0;
    background-color: $color-white;
  }
}

.Toastify__toast-container {
  top: 88px;
  width: auto;
}

.date-picker-labclick {
  grid-column-start: 1;
  grid-column-end: 3;

  .react-datepicker {
    font-family: 'Graphik', sans-serif;
    overflow: hidden;
    font-size: 13px;
    line-height: 13px;
    color: $color-primary-redesign;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 0;
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    font-family: 'Graphik';
    color: $color-primary-redesign;
    text-transform: uppercase;
  }

  .react-datepicker__header--time--only {
    display: none;
  }

  ul.react-datepicker__time-list {
    padding-bottom: $gap20;
    margin-left: $gap16;
    border: none;
  }

  .react-datepicker__month-container {
    width: 100%;
    padding-top: $gap42;
  }

  .react-datepicker__month {
    margin: 0;
    padding: $gap8 $gap8 $gap8 $gap8;
    border: 1px solid rgba($color-secondary-redesign, 0.35);
    border-top: none;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: $gap8 $gap8 0 $gap8;
    border: 1px solid rgba($color-secondary-redesign, 0.35);
    border-bottom: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__day-name {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    opacity: 0.5;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day {
    $_days-in-week: 7;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(100% / $_days-in-week / 2 - 9px); // TODO SZ: refactor
    padding-bottom: calc(100% / $_days-in-week / 2 - 9px); // TODO SZ: refactor
    width: 100%;
    font-size: 13px;
    line-height: 13px;
    color: #15281e;
    background-color: $color-white-redesign;

    &:hover {
      background-color: rgba(181, 207, 217, 0.35);
      color: $color-white;
      border-radius: 100%;

      color: $color-primary-redesign-hover;

      &.react-datepicker__day--selected:hover {
        background-color: $color-primary-redesign;
        color: $color-white;
        border-radius: 100%;
      }
    }
  }

  .react-datepicker__day.react-datepicker__day--disabled {
    color: $color-primary-redesign;
    opacity: 0.5;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $color-primary-redesign;
    color: $color-white;
    border-radius: 100%;
  }

  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $color-primary-redesign;
    color: $color-white;
    border-radius: 100%;
  }

  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: $color-primary-redesign;
    color: $color-white;
    border-radius: 100%;
  }

  //.react-datepicker__day:hover {
  //  background-color: rgba(181, 207, 217, 0.35);
  //  color: $color-white;
  //  border-radius: 100%;
  //
  //  color: $color-primary-redesign-hover;
  //
  //  &.react-datepicker__day--selected:hover {
  //    background-color: $color-primary-redesign;
  //    color: $color-white;
  //    border-radius: 100%;
  //  }
  //}
}

.carousel {
  padding-bottom: $gap24;

  .react-multi-carousel-dot {
    button {
      background: $color-geyser;
      border-color: $color-geyser;
      width: 8px;
      height: 8px;
      transition: border-color $transitionTime, background-color $transitionTime;

      &:active,
      &:hover,
      &:visited {
        background: $color-brand;
        border-color: $color-brand;
      }

      &:hover:active {
        background: $color-brand;
        border-color: $color-brand;
      }
    }

    &.react-multi-carousel-dot--active {
      button {
        background: $color-brand;
        border-color: $color-brand;
      }
    }
  }
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.21967 5.46967C4.51256 5.17678 4.98744 5.17678 5.28033 5.46967L8.75 8.93934L12.2197 5.46967C12.5126 5.17678 12.9874 5.17678 13.2803 5.46967C13.5732 5.76256 13.5732 6.23744 13.2803 6.53033L9.28033 10.5303C8.98744 10.8232 8.51256 10.8232 8.21967 10.5303L4.21967 6.53033C3.92678 6.23744 3.92678 5.76256 4.21967 5.46967Z' fill='%231D1F26'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;
  padding-right: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $color-black;
}

.birthdayPicker {
  .react-datepicker__header__dropdown {
    padding-top: $gap8;
  }

  .react-datepicker__current-month,
  .react-datepicker__navigation {
    display: none;
  }
}

.react-datepicker .react-datepicker__input-time-container {
  .react-datepicker-time__caption {
    display: none;
  }
}

.birthdayCalendar {
  width: 100%;
  border-radius: $_border-radius-common;

  .react-datepicker__day-names {
    border: none;
  }

  .react-datepicker__month {
    border: none;
  }

  .react-datepicker__month-container {
    padding: 0;
    border: none;
  }
}
