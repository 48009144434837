@import "@styles/main.scss";
.root {
  width: 100%;
  border: 1px solid $color-input-border-focus;
  border-radius: $_border-radius-common;
  padding: $gap16 $_input-padding-horizontal;
  height: $_input-height;
  transition: border-color $transitionTime;
  background-color: $color-white-redesign;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px white inset;
    box-shadow: 0 0 0 60px white inset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: $color-placeholder;
  }

  &:disabled {
    color: $color-input-disabled;
    cursor: not-allowed;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    padding-left: calc($_input-padding-horizontal - $_active-input-border-width / 2);
    padding-right: calc($_input-padding-horizontal - $_active-input-border-width / 2);
    border-width: 2px;
    border-color: $color-input-border-focus;
  }

  &.hasLabel {
    padding-bottom: $gap4;
    font-family: $default-font;

    &::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    &:placeholder-shown {
      & + .label {
        transform: translateY(0);
      }
    }

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown),
    &.focus {
      + .label {
        transform: translateY(-35%);
        font-size: 11px;
        line-height: 11px;
        color: $color-placeholder;
      }
    }
  }

  &.error {
    border-color: $color-error;
    color: $color-error;

    & + .label {
      color: $color-error !important; // TODO: remove important
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      border-color: $color-error;
    }
  }
}

.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: $_input-padding-horizontal + 1;
  display: flex;
  align-items: center;
  margin: auto;
  height: 32px;
  pointer-events: none;
  transition: transform 0.15s ease-in-out, font-size 0.15s ease-in-out;
  color: $color-placeholder;
}
