@import "@styles/main.scss";
@import './src/styles/variables';
@import './src/styles/mixins';

.root {
  display: inline-block;
  padding: $gap12 $gap16;
  border: $main-border;
  border-radius: 80px;
  cursor: pointer;
  text-align: center;
  transition: background-color $transitionTime;

  @include hover {
    background-color: $footer-hover-color;
  }

  @include media-mobile {
    display: inline-block;
    margin-left: $gap4;
  }
}

.wrapper {
  position: relative;
}

.text {
  text-decoration-line: underline;
}
