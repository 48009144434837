@import "@styles/main.scss";
button.closeButton {
  position: absolute;
  color: $color-black;
  right: 40px;
  top: 40px;

  width: 48px;
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: background-color $transitionTime;

  &:hover {
    background-color: $color-geyser;

    @include media-tablet {
      background-color: transparent;
    }
  }

  @include media-tablet {
    left: 12px;
    right: auto;
    top: 0;
  }

  @include media-mobile {
    left: 12px;
    right: auto;
    top: 0;
    width: auto;
    height: auto;
    min-height: auto;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
