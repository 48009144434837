@import "@styles/main.scss";
.root {
  position: sticky;
  top: 100vh;
  color: $footer-color;
  background-color: $footer-bg;

   @include media-tablet {
     padding-top: $gap24;
   }
}
