@import './variables';

@mixin media-laptop {
  @media (max-width: $laptop) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin hover {
  @media (hover: none) {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:active {
      @content;
    }
  }

  @media (-ms-high-contrast: none),
    (-ms-high-contrast: active),
    (-moz-touch-enabled: 0),
    (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin flex-gap($row-gap, $column-gap: $row-gap) {
  & > * {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$column-gap;
  }

  & > * > * {
    display: flex;
    margin-top: $column-gap;
  }

  &:not([dir='rtl']) {
    & > * {
      margin-left: -$row-gap;
    }
    & > * > * {
      margin-left: $row-gap;
    }
  }

  &[dir='rtl'] {
    & > * {
      margin-right: -$row-gap;
    }
    & > * > * {
      margin-right: $row-gap;
    }
  }
}

@mixin container-tablet-full-width {
  @include media-tablet() {
    width: calc(100% + $mobile-container-gap * 2);
    padding-left: $mobile-container-gap;
    padding-right: $mobile-container-gap;
    margin-left: -$mobile-container-gap;
    margin-right: -$mobile-container-gap;
  }
}

@mixin container-layout {
  padding-left: $desktop-container-gap;
  padding-right: $desktop-container-gap;
  margin: 0 auto;

  @include media-tablet() {
    padding-left: $mobile-container-gap;
    padding-right: $mobile-container-gap;
  }
}
