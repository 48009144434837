@import "@styles/main.scss";
$_button-top-offset: 8px;
$_button-width: 80px;
$_button-height: 5px;

.root {
  position: fixed;
  bottom: 0;
  height: $_bottom-bar-mobile-height;
  width: 100%;
  background: $color-white;
  border-radius: $_border-radius-medium $_border-radius-medium 0px 0px;
  transform: translateY(100vh);
  transition: transform 500ms;
  overflow-y: auto;

  &.open {
    transform: translateY(0);
  }
}

.content {
  height: calc(100% - $_button-top-offset - $_button-height);
  overflow-y: auto;
}

.closeButton {
  position: relative;
  top: $_button-top-offset;
  display: block;
  width: $_button-width;
  height: $_button-height;
  min-height: unset;
  margin: 0 auto;
  margin-bottom: $gap8;
  background-color: $color-secondary-redesign;
  border-radius: $_border-radius-common;
}
