@import "@styles/main.scss";
$_calendar-icon-width: 24px;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monthTitle {
  @include type-calendar-title;
  text-transform: capitalize;
  color: $color-black;
}

.monthTogglers {
  & > *:first-child {
    margin-right: $gap12;
  }
}

.icon {
  & > * {
    height: $_calendar-icon-width;
    width: $_calendar-icon-width;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.mirroredIcon {
  svg {
    transform: rotate(180deg);
  }
}
