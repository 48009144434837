@import "@styles/main.scss";
.root {
  span {
    font-size: 18px;
    line-height: 20px;
  }

  button:nth-child(2) {
    margin-top: $gap16;
  }
}
