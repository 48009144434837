@import "@styles/main.scss";
.root {
  padding-top: 7%;

  & div {
    width: 100%;
    margin-right: 16px;

    .input {
      border-radius: 20px 20px 20px 20px;
    }
  }
}

.input {
  font-family: 'Graphik';
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  border: 1px solid $color-divider;
  height: 68px;
  min-width: 100%;
  position: relative;
  z-index: 1;
  transition: border-color $transitionTime, color $transitionTime;

  &::placeholder {
    color: $color-placeholder;
  }

  &.error {
    border-color: $color-error;
    color: $color-error;
  }
}

.focusStyle {
  border-color: $color-brand;
  z-index: 2;
  
  &::placeholder {
    opacity: 0;
  }
}