@import "@styles/main.scss";
.root {
  text-align: center;
}

.text {
  text-align: center;
}

.link {
  color: $color-brand;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  transition: color $transitionTime;

  &:hover {
    color: $color-brand-hover
  }
}

.time {
  min-width: 38px;
  display: inline-block;
  text-align: left;
}
