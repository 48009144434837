@import "@styles/main.scss";
.root {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $_carousel-arrow-width;
  height: $_carousel-arrow-width;
  background: $color-secondary-redesign;
  border-radius: 50%;
  margin: 0;

  svg {
    margin: 0;
  }

  @include hover {
    cursor: pointer;
  }
}

.arrowRight {
  right: $_carousel-card-gap;

  svg path {
    fill: none !important;
  }
}

.arrowLeft {
  left: $_carousel-card-gap;

  svg path {
    fill: none !important;
  }
}
