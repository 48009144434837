@import "@styles/main.scss";
.linkText {
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  transition: background-color $transitionTime;
  padding: $gap8;
}

.shortName {
  display: inline-block;
  padding: 0;
  width: $_profile-short-name-height;
  height: $_profile-short-name-height;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  line-height: $_profile-short-name-height;
  border-radius: 50%;
  background-color: $short-name-bg;
  color: $short-name-color;

  @include media-mobile {
    width: $_header-icon-size-mobile;
    height: $_header-icon-size-mobile;
    font-size: 12px;
    line-height: $_header-icon-size-mobile;
  }
}
