@import "@styles/main.scss";
.formItem {
  margin-bottom: $gap32;
}


.errorText {
  color: $color-error;
  text-align: center;
  margin-top: $gap8;
  font-weight: 700;
}