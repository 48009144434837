@import "@styles/main.scss";
$select-padding-horizontal: $gap16;

.root {
  position: relative;
  height: fit-content;
}

.icon {
  position: absolute;
  right: 16px;
  top: 0;
  height: 20px;
  bottom: 0;
  margin: auto;
  color: $color-black;
  pointer-events: none;

  &.active {
    transform: rotate(180deg);
    transform-origin: center;
  }
}

.value {
  width: 100%;
  border: 1px solid $color-primary-redesign;
  border-radius: 16px;
  padding: $gap16 $select-padding-horizontal;
  padding-bottom: $gap4;
  height: 52px;
  font-family: $default-font;
  transition: border-color $transitionTime;
  padding-right: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $color-white;

  &:not(.disabled) {
    &:hover {
      border-color: $color-input-border-focus;
    }

    &.error {
      border-color: $color-error;
      color: $color-error;
    }
  }

  &.active {
    border-color: $color-input-border-focus;
  }
}

.label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: $select-padding-horizontal + 1;
  margin: auto;

  color: $color-black-redesign;

  font-size: 16px;
  line-height: 20px;
  font-family: 'Graphik';
  height: 20px;
  pointer-events: none;
  transition: transform 0.15s ease-in-out, font-size 0.15s ease-in-out;

  &.active {
    transform: translateY(-65%);
    font-size: 12px;
    color: #c4c4c4;
  }

  &.error {
    color: $color-error;
  }
}

.option {
  font-family: $default-font;
  color: $color-primary-dark-redesign;

  &.active {
    color: $color-primary-light-redesign;
  }
}

.listClasses {
  position: absolute;
}