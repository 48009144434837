@import "@styles/main.scss";
.root {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $_border-radius-round;

  & > svg {
    margin-right: $gap12;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.loading > *:not(.loader) {
    opacity: 0;
  }

  transition: background-color $transitionTime, color $transitionTime;
}

// themes
.theme-primary {
  border-radius: $_border-radius-round;
  background-color: $button-primary-bg;
  color: $button-primary-color;

  svg path {
    stroke: $button-primary-color;
    fill: $button-primary-color;
  }

  @include hover {
    &:not(:disabled) {
      background-color: $button-primary-hover-bg;
      color: $button-primary-hover-color;

      svg path {
        stroke: $button-primary-hover-color;
        fill: $button-primary-hover-color;
      }
    }
  }

  &:disabled {
    background-color: $button-primary-disabled-bg;
  }
}

.theme-secondary {
  border-radius: $_border-radius-round;
  color: $button-secondary-color;
  background-color: $button-secondary-bg;

  svg path {
    stroke: $button-secondary-color;
    fill: $button-secondary-color;
  }

  @include hover {
    &:not(:disabled) {
      background-color: $button-secondary-hover-bg;
      color: $button-secondary-hover-color;

      svg path {
        stroke: $button-secondary-hover-color;
        fill: $button-secondary-hover-color;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

// TODO Begin - Old Themes will be removed
.theme-gray {
  padding: 0 $gap20;
  border-radius: $_border-radius-round;
  background-color: transparent;
  border: 1px solid $color-gray;
  color: $color-black;
  font-weight: 600;

  @include hover {
    &:not(:disabled) {
      opacity: 0.75;
    }
  }
}

.theme-gray-outline {
  padding: $gap8 $gap12;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid $color-geyser;
  color: $color-black;
  font-weight: 400;
  text-align: center;

  @include hover {
    &:not(:disabled) {
      opacity: 0.75;
    }
  }

  &:disabled {
    background-color: $color-geyser;
  }
}
// TODO End - Old Themes will be removed

//sizes
.size-small {
  min-height: 24px;
}

.size-medium {
  padding: $gap4 $gap16;
  min-height: $_button-medium-height;
}

.size-large {
  padding: $gap4 $gap16;
  min-height: $_button-large-height;

  @include media-tablet {
    min-height: $_button-medium-height-mobile;
  }
}

.size-large-plus {
  padding: $gap16 $gap32;
  min-height: $_button-large-height;
}

.ghost {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}

.fullWidth {
  width: 100%;
  text-align: center;
}

.loader {
  display: none;

  &.active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      animation-name: spin;
      animation-duration: 800ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}
