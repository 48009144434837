@import "@styles/main.scss";
.root {
  display: flex;
}

.row {
  display: flex;
}

.alignCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.checkBox {
  align-items: baseline;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > .row:first-child {
    @include type-button-small;
    color: $color-black-redesign;
    margin-bottom: $gap8;
  }

  & > .row:nth-child(2) {
    @include type-caption-small;
    color: $color-primary-redesign-hover;
  }
}

.contentWrapper {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  margin: 0 $gap16;

  svg {
    margin-right: $gap4;
  }
}

.code {
  padding: $gap4;
  margin-right: $gap16;
  border: 1px solid rgba($color-primary-redesign, 0.3);
  border-radius: $_border-radius-small;
}

.iconButton {
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  padding-left: $gap2;
  padding-right: $gap2;
  white-space: nowrap;
  max-width: 116px;
  width: 100%;

  & > :first-child {
    margin-right: $gap8;
  }

  &.booked {
    & > :first-child {
      margin-right: 0;
    }
  }
}
