@import "@styles/main.scss";
.root {

}

.carouselWrapper {
  position: relative;
  min-height: 90px;
}

.carouselContainer {
  @include container-tablet-full-width();
  position: relative;
  z-index: 2;
  margin: 0 (-(calc($_carousel-card-gap / 2)));

  @include media-tablet {
    $_carousel-mobile-gap: calc($_carousel-card-gap / 2) + $tablet-container-gap;
    margin: 0 (-$_carousel-mobile-gap);
    width: calc(100% + $_carousel-mobile-gap * 2);
  }
}

.carouselItem {

}
