@import "@styles/main.scss";
.root {
  border: 1px solid $color-input-border-focus;
  border-radius: 16px;
  padding: $gap16 $_input-padding-horizontal;
  min-height: 52px;
  transition: border-color $transitionTime;
  resize: none;
  width: 100%;
  min-width: 100%;
  background-color: $color-white;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px white inset;
    box-shadow: 0 0 0 60px white inset;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  &::placeholder {
    color: $color-placeholder;
  }

  &:disabled {
  }

  &:focus,
  &:hover,
  &:active {
    border-color: $color-input-border-focus;
  }

  &.hasLabel {
    padding-top: $gap24;

    &::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }

    &:placeholder-shown {
      & + .label {
        transform: translateY(0);
      }
    }

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown),
    &.focus {
      + .label {
        transform: translateY(-35%);
        font-size: 11px;
        line-height: 11px;
        background-color: $color-white;
        color: $color-primary-redesign;
        opacity: 0.4;
      }
    }
  }
}

.label {
  position: absolute;
  top: $gap16;
  left: $_input-padding-horizontal + 1;
  width: calc(100% - 40px);
  color: $color-placeholder;
  pointer-events: none;
  transition: transform 0.15s ease-in-out, font-size 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
