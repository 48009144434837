// TODO no these font in the design mockups, remove after testing
//@font-face {
//  font-family: 'Halvar Breitschrift CY';
//  src: url('/fonts/halvar_breitschrift-regular-web-webfont.woff2') format('woff2'),
//       url('/fonts/halvar_breitschrift-regular-web-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Halvar Breitschrift CY';
//  src: url('/fonts/halvar_breitschrift-medium-web-webfont.woff2') format('woff2'),
//       url('/fonts/halvar_breitschrift-medium-web-webfont.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'Halvar Breitschrift CY';
//  src: url('/fonts/halvar_breitschrift-bold-web-webfont.woff2') format('woff2'),
//       url('/fonts/halvar_breitschrift-bold-web-webfont.woff') format('woff');
//  font-weight: 700;
//  font-style: normal;
//}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/GraphikLCG-Regular.woff2') format('woff2'), url('/fonts/GraphikLCG-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

// TODO no these font in the design mockups, remove after testing
//@font-face {
//  font-family: 'Graphik';
//  src: url('/fonts/GraphikLCG-Medium.woff2') format('woff2'),
//       url('/fonts/GraphikLCG-Medium.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//  font-stretch: normal;
//}
//
//@font-face {
//  font-family:'Graphik';
//  src: url('/fonts/GraphikLCG-Semibold.woff2') format('woff2'),
//       url('/fonts/GraphikLCG-Semibold.woff') format('woff');
//  font-weight: 600;
//  font-style: normal;
//  font-stretch: normal;
//}

@font-face {
  font-family: 'Platform LC';
  src: url('/fonts/Platform-Regular.otf') format('opentype'), url('/fonts/Platform-Regular.woff2') format('woff2'),
    url('/fonts/Platform-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
