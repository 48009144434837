@import "@styles/main.scss";
.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr auto;
  column-gap: $gap8;
  row-gap: $gap8;

  .choosedButton.timeSlot {
    background-color: $color-primary-redesign;

    span {
      color: $color-white;
    }
  }

  .timeSlot {
    padding: 7px 20px;
    text-align: center;
    border: 1px solid $color-secondary-redesign;
    border-radius: 10px;
    height: 45px;

    span {
      font-family: 'Graphik';
      font-size: 13px;
      line-height: 13px;
      color: #15281e;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .pureText {
    font-family: $default-font;
  }
}
