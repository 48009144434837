@import "@styles/main.scss";
.root {
  @include type-button-small;
  padding: $gap16 $gap24;
  background-color: $color-white-redesign;
  cursor: pointer;
  transition: color 0.15s;
  border-bottom: $main-border;
  color: $muted-text-color;

  &:first-child {
    padding-top: $gap24;
  }

  &:last-child {
    padding-bottom: $gap24;
    border-bottom: none;
  }

  &.active {
    color: $main-link-color;
  }

  @include hover {
    color: $main-link-color;
  }
}
