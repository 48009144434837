@import "@styles/main.scss";
.root {
  width: 100%;
}

.calendarHeaderWrapper {
  position: absolute;
  width: 100%;
  top: -$gap48;
  padding: $gap12 0;
}

.content {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: $gap16;
  // column-gap: $gap12;
}

.calendarBody {
  border: none;
  width: 100%;
}

.calendarTimeBody {
  border: none;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &:-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.timeSlots {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: -1;
}

.loader {
  min-height: 124px;
}

.dayCell {
  padding: 7px;
  margin: 3px;
  font-size: 13px;
  line-height: 13px;
  color: #15281e;
}

.dayCellActive {
  background-color: $color-primary-redesign;
  color: $color-white;
  border-radius: 100%;
  transition: background-color $transitionTime;
}

.dayCellDisabled {
  color: $color-secondary-redesign;
}

.chooseDateButton {
  grid-column-start: 1;
  grid-column-end: -1;
  padding: $gap12 $gap16;
  // color: $color-primary-redesign;
  // background-color: $color-secondary-redesign;
  border: none;
  border-radius: 30px;
  height: $gap40;

  &:disabled {
    color: $color-primary-redesign;
    opacity: 0.35;
    background-color: $color-secondary-redesign;
  }

  span {
    font-family: $default-font;
    font-size: 16px;
    line-height: 127%;
    font-weight: 400;
  }
}
