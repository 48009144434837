@import "@styles/main.scss";
$_header-small-height: 88px;
$_header-small-mobile-height: 68px;

header.root {

}

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  height: $_header-small-height;

  @include media-tablet {
    height: $_header-small-mobile-height;
    border-bottom: $main-border;
  }
}

.closeButton {
  padding: 0 !important;

  svg g {
    opacity: 1;
  }

  svg path {
    fill: $close-button-bright-color;
  }

  @include hover {
    svg g {
      opacity: 0.8;
    }
  }
}
