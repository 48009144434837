@import "@styles/main.scss";
$default-padding: $gap32;
$active-padding: $default-padding - 1px;
$default-padding-mobile: $gap24;
$active-padding-mobile: $default-padding-mobile - 1px;

.root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: $default-padding;
  background-color: $color-white-redesign;
  border: $main-border;
  border-radius: $_border-radius-common;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  @include media-tablet {
    padding: $default-padding-mobile;
  }

  &:not(:first-child) {
    margin-top: $gap16;
  }

  @include hover {
    padding: $active-padding;
    border: 2px solid $color-primary-redesign;

    @include media-tablet {
      padding: $active-padding-mobile;
    }
  }

  &.active {
    padding: $active-padding;
    border: 2px solid $color-primary-redesign;

    @include media-tablet {
      padding: $active-padding-mobile;
    }
  }
}

.content {
  display: flex;
  align-items: flex-start;
}

.image {
  flex-shrink: 0;
  margin-right: $gap24;
}

.naming {
  display: flex;
  flex-direction: column;
}

.title {
  color: $color-primary-dark-redesign;
}

.notice {
  margin-top: $gap4;
  color: $color-primary-redesign;
}

.checkBox {
  flex-shrink: 0;
  margin-left: $gap16;
  margin-right: -$gap14;
}
