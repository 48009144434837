@import "@styles/main.scss";
$footer-arrow-size: 20px;

.root {
  position: relative;

  & > * {
    margin-bottom: $gap24;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-tablet {
    margin-bottom: $gap24;
    padding: $gap4 0;
  }

  &.active {
    @include media-tablet {
      .arrow {
        transform: rotate(180deg);
      }

      & + .navigationList {
        max-height: 500px;
        opacity: 1;
      }
    }
  }
}

.navigationTitle {
}

.item {
  position: relative;
}

.navigationLink {
  display: inline-block;
  cursor: pointer;
  transition: color $transitionTime;

  @include hover {
    opacity: 0.5;
  }
}

.navigationList {
  margin: 0;
  padding: 0;
  list-style: none;
  transition: max-height $transitionTime, opacity $transitionTime;

  @include media-tablet {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.navigationListItem {
  max-width: 186px;
  margin-bottom: $gap24;

  &:last-child {
    margin-bottom: 0;

    @include media-tablet {
      margin-bottom: $gap24;
    }
  }

  @include media-tablet {
    max-width: 100%;
  }
}

.arrow {
  display: none;
  transition: transform $transitionTime;

  @include media-tablet {
    display: inline-block;
    width: $footer-arrow-size;
    height: $footer-arrow-size;
    line-height: $footer-arrow-size;
    background: $footer-color;
    border-radius: 50%;
    text-align: center;

    svg {
      width: 16px;
      display: inline-block;

      path {
        stroke: $footer-bg;
        fill: $footer-bg;
      }
    }
  }
}

.text {
}
