@mixin typo-font-Platform {
  font-family: 'Platform LC', sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin type-hero {
  @include typo-font-Platform;
  font-size: 82px;
  line-height: 98px;

  @include media-tablet {
    font-size: 48px;
    line-height: 46px;
  }
}

@mixin type-h1 {
  @include typo-font-Platform;
  font-size: 54px;
  line-height: 55px;

  @include media-tablet {
    font-size: 32px;
    line-height: 38px;
  }
}

@mixin type-h2 {
  @include typo-font-Platform;
  font-size: 26px;
  line-height: 31px;

  @include media-tablet {
    font-size: 22px;
    line-height: 26px;
  }
}

@mixin type-h3 {
  @include typo-font-Platform;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

@mixin type-button {
  @include typo-font-Platform;
  font-size: 18px;
  line-height: 110%;
}

@mixin type-button-small {
  @include typo-font-Platform;
  font-size: 16px;
  line-height: 125%;
}

@mixin type-text-small {
  @include typo-font-Platform;
  font-size: 16px;
  line-height: 24px;
}

@mixin type-caption {
  font-family: 'Graphik LCG', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
}

@mixin type-caption-small {
  font-family: 'Graphik LCG', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 100%;
  text-transform: uppercase;
}

@mixin type-calendar-title {
  @include typo-font-Platform;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;

  @include media-tablet {
    font-size: 22px;
    line-height: 26px;
  }
}

@mixin type-footer-title {
  font-family: 'Graphik', sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

@mixin type-footer-text {
  font-family: 'Graphik', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0;
}

@mixin type-h1-5 {
  @include typo-font-Platform;
  font-size: 42px;
  line-height: 50px;
}
