@import "@styles/main.scss";
.root {
  @include media-tablet {
    padding-top: 80px;
  }
}

.title {
  margin-bottom: $gap8;
  font-family: $default-font;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
}

.description {
  padding-top: 8px;
  font-family: $default-font;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;

  color: rgba(0, 0, 0, 0.5);
}

.phone {
  color: $color-brand;
}

.phoneInput {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;

  width: 531px;
  height: 52px;
}

.authForm {
  padding-top: 7%;
}
