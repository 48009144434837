@import "@styles/main.scss";
.root {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: $gap20;

  @include media-tablet {
    display: block;
    order: 4;
    width: 100%;
    margin: $gap16 0;

    .searchInput {
      margin-right: 0;
    }
  }
}

.search {
  position: relative;
  color: $header-search-color;
}

.searchInput {
  border-radius: $_border-radius-round;
  margin-right: $gap12;
  padding-right: $gap44;

  &:focus:not(:disabled),
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border: 1px solid $color-input-border;
    padding: $gap16;
    padding-right: $gap44;
  }

  @include media-tablet {
    border: none;
    background: $main-placeholder-bg;
    padding-right: $gap16;
    padding-left: $gap44;

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      padding: $gap16;
      padding-left: $gap44;
    }
  }
}

.searchIcon {
  position: absolute;
  right: $gap20;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%);

  &.iconMob {
    display: none;
  }

  @include media-tablet {
    left: $gap16;
    right: auto;
    line-height: 1;
    display: none;

    &.iconMob {
      display: block;
    }

    svg {
      width: $gap16;
    }
  }
}

.loader {
  height: 100%;
}

.list {
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  overflow: hidden;
  background-color: $color-white;
  max-height: 1000px;
  position: absolute;
  width: 100%;
  min-width: 720px;
  left: 0;
  top: 0;
  border: 1px solid $color-secondary-redesign;
  box-shadow: $box-shadow-wide;
  border-radius: $_border-radius-medium;

  padding: $gap24;

  &.active {
    z-index: 1;
  }

  @include media-tablet {
    min-width: 100%;
    padding: $gap12;
  }
}

.value {
  width: 100%;
  border: 1px solid $color-input-border;
  border-radius: 16px;
  padding: $gap16 $gap24;
  padding-bottom: $gap4;
  height: 52px;
  transition: border-color $transitionTime;
  padding-right: $gap40;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:not(.disabled) {
    &:hover {
      border-color: $color-input-border-focus;
    }
  }

  &.active {
    border-color: $color-input-border-focus;
  }
}

.option {
  background-color: $color-white;
  cursor: pointer;
  transition: color 0.15;
}

.divider {
  margin-top: $gap16;
  margin-bottom: $gap16;

  @include media-tablet {
    margin-top: $gap8;
    margin-bottom: $gap24;
  }
}

.allResearches {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: $gap8;

  color: $color-primary-light-redesign;
}

.rightArrowIcon {
  padding-top: $gap2;
}
