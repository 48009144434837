@import "@styles/main.scss";
.root {
  display: flex;
  align-items: stretch;

  position: relative;

  &.err:not(.disabled) {
  
  }

  &:hover:not(.disabled, .err) {
  
  }

  &.disabled {
  
    .preContent, .postContent {
      pointer-events: none;
    }
  }
}

.preContent, .postContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $color-black;
}

.preContent {
  margin-right: 16px;
}

.postContent {
  margin-left: 16px;
}

