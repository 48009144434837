@import "@styles/main.scss";
.root {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: 242px;
  box-sizing: border-box;
  list-style: none;
  border-radius: $_border-radius-small;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: $box-shadow-wide;
  border-radius: $_border-radius-common;
  z-index: 1;

  &.active {
    display: block;
  }
}
