@import "@styles/main.scss";
.root {
  position: relative;
  display: block;
  width: $_image-small-size;
  height: $_image-small-size;
  cursor: pointer;
}

.withTitle {
  width: 176px;

  @include media-tablet {
    width: 124px;
  }
}
