@import "@styles/main.scss";
.root {
  display: flex;
  margin: 0 auto;
  flex-flow: row nowrap;

  @include media-tablet {
    flex-flow: column nowrap;
  }
}

.quotesWrapper {
  position: relative;
  margin-right: $gap48;
  margin-top: $gap8;
  max-width: 66px;
  min-width: 66px;
  height: 53px;

  @include media-tablet {
    max-width: 53px;
    min-width: 53px;
    height: 42px;
    margin-top: $gap32;
    margin-bottom: $gap20;
  }
}

.textWrapper {
  @include type-h1();
  display: flex;
  flex-direction: column;

  .author {
    display: flex;
    align-items: center;
    margin-top: $gap24;
  }

  .authorDescription {
    @include type-button();
    display: flex;
    flex-direction: column;
    margin-left: $gap32;

    .authorName {
      margin-bottom: $gap8;
    }

    .authorPost {
    }
  }
}
