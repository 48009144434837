@import "@styles/main.scss";
$labs-popup-gap: $gap24;

.root {

}

.label {
  display: flex;
  align-items: center;

  @include hover {
    cursor: pointer;
  }
}

.labelText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: rgba($color-primary-redesign, 0.4);
}

.values {
  position: absolute;
  right: -34px;
  bottom: 0;
  transform: translateY(100%);
  background: $color-white-redesign;
  display: flex;
  min-width: 55%;
  flex-direction: column;
  align-items: flex-start;
  padding: $labs-popup-gap;
  box-shadow: $form-box-shadow;
  border: $_main-border-width solid $color-secondary-redesign;
  border-radius: $_border-radius-common;
  z-index: 50;

  @include media-tablet {
    width: calc(100% + $labs-popup-gap * 2 + $_main-border-width * 2);
    right: -25px;
  }
}

.value {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $gap16;
  color: $color-primary-dark-redesign;

  @include hover {
    cursor: pointer;
    color: $main-link-color;
  }

  flex: none;
  align-self: stretch;
  flex-grow: 0;

  @include media-tablet {
    width: 100%;
  }
}

.divider {
  width: 100%;
  margin: $gap16 0;
}

.currentLab {
  color: $color-primary-light-redesign;
}

.checkIcon {
  margin-left: auto;
}
