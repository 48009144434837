@import "@styles/main.scss";
@import "./src/styles/variables";
@import "./src/styles/mixins";

.formItem {
  margin-bottom: $gap8;
}

.button {
  margin-bottom: $gap32;
  margin-top: $gap32;
  background: $color-primary-redesign;
  border-radius: 30px;

  font-family: $default-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 110%;
}

.text {
  font-family: $default-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 110%;
  /* or 20px */

  text-align: center;

  a {
    color: $color-brand;
    cursor: pointer;
  }
}

.errorText {
  color: $color-error;
  text-align: center;
  margin-bottom: $gap8;
  font-weight: 700;
}

.phoneInput {
  font-family: $default-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
}
