@import "@styles/main.scss";
.root {
  display: inline-block;
  padding: $gap12 $gap16;
  border: $main-border;
  border-radius: 80px;
  text-align: center;
  transition: background-color $transitionTime;

  @include hover {
    background-color: $footer-hover-color;
    cursor: pointer;
  }
}

.wrapper {
  position: relative;
}

.text {
}
