@import "@styles/main.scss";
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.topContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 100%;
}

.firstRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 100%;
}

.title {
  @include type-button();
  order: 0;
  flex-grow: 1;
}

.price {
  @include type-button();
  padding-left: $gap24;
  color: $color-primary-light-redesign;
  white-space: nowrap;
  order: 1;
  flex-grow: 0;
}

.labelsRow {
  margin-top: $gap12;
}

.labRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: $gap16;

  @include media-tablet {
    margin-top: $gap8;
  }
}

.labName {
  @include type-button-small();
  color: $color-primary-light-redesign;
}

.code {
  @include type-caption-small();
  color: $color-primary-redesign;
  padding: $gap4;
  margin-right: $gap16;
  border: 1px solid rgba($color-primary-redesign, 0.3);
  border-radius: $_border-radius-small;
}

.item {
  @include type-caption-small();
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  color: $color-primary-redesign;

  & > svg {
    margin-right: $gap4;
  }
}
