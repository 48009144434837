@import "@styles/main.scss";
.container {
  background-color: $color-white-redesign;
}

.root {
  position: relative;
  padding: $gap32;
  border: 1px solid $color-primary-redesign;
  border-radius: $_border-radius-medium;
  margin-bottom: $gap16;
  background-color:  $color-white-redesign;

  @include media-tablet {
    padding: $gap24;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  white-space: nowrap;
  display: block;
  flex-shrink: 0;
  color: $price-color;
  text-align: right;
}

.title {
}

.content {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: $gap16;
}

.labLabels {
  width: 100%;
  grid-column: 1 / 3;
}

.labsPopUp {
  position: relative;
  width: 100%;
}

.labsList {
  margin-top: $gap8;
}

.divider {
  margin-top: $gap32;
  margin-bottom: $gap28;

  @include media-tablet {
    margin-top: $gap24;
    margin-bottom: $gap20;
  }
}
