@import "@styles/main.scss";
.root {
  margin: $gap8 calc($_carousel-card-gap / 2) 0;

  @include media-tablet {
    margin: 0 $gap8;
  }
}

.content {
  cursor: pointer;

  @include hover() {
    .title {
      color: $main-link-color;
    }
  }
}

.image {
  position: relative;
  padding-bottom: $_category-card-aspect-ratio * 100%;
  border-radius: $_border-radius-medium;
  overflow: hidden;
  margin-bottom: $gap16;
  background: $category-card-bg;
}

.title {
  margin-bottom: $gap12;
}

.price {
  color: $color-primary-redesign;
}
