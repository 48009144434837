$mobile: 480px;
$tablet: 900px;
$laptop: 1200; //1024px;

$container-width: 1060px; //1080px;
$container-medium-width: 1060px;
$container-small-width: 628px; //592px;

//gaps

$gap2: 2px;
$gap4: 4px;
$gap8: 8px;
$gap12: 12px;
$gap16: 16px;
$gap20: 20px;
$gap24: 24px;
$gap26: 26px;
$gap28: 28px;
$gap32: 32px;
$gap36: 36px;
$gap40: 40px;
$gap44: 44px;
$gap48: 48px;
$gap56: 56px;
$gap68: 68px;

//////////////////////////////
$gap14: 14px;
$gap22: 22px;
$gap42: 42px;
$gap64: 64px;
$gap72: 72px;
$gap96: 96px;

$gap112: 112px;
$gap128: 128px;

$_image-small-size: 36px;
$_image-small-size-mobile: 24px;
$_image-medium-size: 44px;

$_button-medium-height: 32px;
$_button-large-height: 48px;
$_button-medium-height-mobile: 40px;

$_carousel-card-gap: $gap24;
$_header-height: 90px;
$_header-top-gap: $gap20;
$_header-icon-item-size: 52px;
$_header-icon-item-size-mobile: 28px;
$_header-icon-size: 36px;
$_header-icon-size-mobile: 24px;

$_carousel-arrow-width: 44px;
$_menu-item-height: 44px;
$_profile-short-name-height: 36px;

$_border-radius-round: 60px;
$_border-radius-medium: 20px;
$_border-radius-common: 16px;
$_border-radius-small: 5px;

$_menu-icon-width: 52px;
$_basket-count-width: 20px;
$_basket-count-width-large: 30px;
$_basket-count-width-mobile: 16px;
$_hero-search-form-padding: $gap12;
$_tag-image-with: 46px;
$_input-padding-horizontal: $gap16;
$_active-input-border-width: 2px;
$_bottom-bar-mobile-height: 92%;

$_desktop-container-gap:  $_carousel-card-gap * 2;
$desktop-container-gap: $_carousel-arrow-width + $_desktop-container-gap;  //--desktop-container-gap;
$tablet-container-gap: $gap20;
$mobile-container-gap: $gap20;

$desktop-max-width: 1278px; //1700px;
$demo-screen-width: 1080px;

// $mobile-container-gap: $gap12;
$_category-card-max-width: 300px;
$_category-card-aspect-ratio: 0.6;
$_product-page-bottom-gap: $gap24;

$_birthday-calendar-width: 350px;
$_birthday-calendar-max-width: 50%;
$_birthday-calendar-max-width-mobile: 75%;
