/////////////  Old design variables
//colors
$color-black: #1d1f26;
$color-white: #ffffff;
$color-gray: #909999;
$color-tonys-pink: #e19382;
$color-sea-green: #2a7258;
$color-geyser: #dde1e5;
$color-westar: #dbd9cf;
$color-athens-gray: #f7f7f9;
$color-alabaster: #f7f5ed;
$color-athens-gray: #f6f7f9;

$color-bg: $color-alabaster;
$color-brand-hover: $color-sea-green;
$color-input-disabled: $color-gray;

$color-address-form-bg: $color-athens-gray;
$color-order-status-bg: $color-athens-gray;
$color-contacts-card-bg: $color-athens-gray;
$color-text-muted: $color-gray;
$color-table-border: $color-geyser;
$color-abbey: #4a4c51;

$transitionTime: 0.2s;
$transition: 0.2s ease;

/////////////  New design variables
//colors
$color-primary-redesign: #3e594b;
$color-primary-dark-redesign: #15281e;
$color-primary-light-redesign: #79b085;
$color-primary-ultra-light-redesign: #c1e8bc;
$color-primary-redesign-disabled: rgba($color-primary-redesign, 0.4);
$color-primary-redesign-hover: #15281e;
$color-danger-redesign: #af5973;
$color-light-inactive: rgba(181, 207, 217);
$color-secondary-redesign: rgba($color-light-inactive, 35%); // rgba(#B5CFD959, 35%); #b5cfd935;
$color-white-redesign: #ffffff;
$color-black-redesign: #000000;
$color-text-light: #fbf9e7;
$color-placeholder: rgba($color-primary-redesign, 0.4);
$color-overlay: rgba(21, 40, 30, 0.48);

$color-froly: #f06b6b;
$color-error: $color-froly;

///Text
$default-font: 'Platform LC', sans-serif;
$main-link-color: $color-primary-light-redesign;
$main-link-hover-color: $color-primary-redesign;
$color-brand: $color-primary-light-redesign;
$primary-text-color: $color-black-redesign;
$primary-text-inverse-color: $color-white-redesign;
$primary-bg-color: $color-white-redesign;
$danger-color: $color-danger-redesign;
$muted-text-color: rgba($color-black-redesign, 0.5);

///Shadows
$form-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 14px rgba(0, 0, 0, 0.05);
$box-shadow-wide: 0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 14px 0px rgba(0, 0, 0, 0.05),
  0px 64px 104px 0px rgba(0, 0, 0, 0.14);
$box-shadow-narrow: 0px 3px 6px rgba(0, 0, 0, 0.06);
$box-shadow-light: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);

///Borders
$_main-border-width: 1px;
$color-input-border: $color-secondary-redesign;
$color-input-border-focus: $color-primary-redesign;
$main-border-color: $color-secondary-redesign;
$main-border: $_main-border-width solid $main-border-color;
$color-divider: $color-secondary-redesign;
$divider-border-color: rgba($color-black-redesign, 0.05);
$card-border-color: rgba($color-light-inactive, 0.5);

// Header
$header-full-bg: $color-primary-redesign;
$header-full-color: $color-white-redesign;
$header-links-hover-bg: $color-secondary-redesign;
$header-search-color: $color-primary-redesign;
$header-bg: $color-white-redesign;
$header-hover-bg: $color-secondary-redesign;
$header-color: $color-black-redesign;
$header-links-border: $main-border;
$header-icons-color: $color-primary-dark-redesign;

// Footer
$footer-bg: $color-primary-redesign-hover;
$footer-color: $color-text-light;
$footer-hover-color: $color-secondary-redesign;

//Buttons
$button-primary-bg: $color-primary-redesign;
$button-primary-color: $color-white-redesign;
$button-primary-hover-bg: $color-primary-redesign-hover;
$button-primary-hover-color: $color-white-redesign;
$button-primary-disabled-bg: $color-primary-redesign-disabled;

$button-secondary-bg: $color-secondary-redesign;
$button-secondary-color: $color-primary-redesign;
$button-secondary-hover-bg: $color-primary-redesign;
$button-secondary-hover-color: $color-white-redesign;
$button-secondary-disabled-bg: $color-primary-redesign-disabled;

$close-button-bright-color: $color-primary-redesign;

//Components and pages
$basket-count-color: $color-white-redesign;
$basket-count-bg: $color-danger-redesign;
$price-color: $color-primary-light-redesign;

$main-placeholder-bg: $color-secondary-redesign;
$category-card-bg: $main-placeholder-bg;

$short-name-bg: $color-primary-ultra-light-redesign;
$short-name-color: $color-black-redesign;

$analises-color: $color-danger-redesign;
$step-card-title-color: $color-primary-redesign;
$step-card-title-dark-color: $color-primary-dark-redesign;
$analises-color: $color-danger-redesign;

$_input-height: 52px;
