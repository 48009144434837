@import "@styles/main.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-right: 2.5%;

    @include media-tablet {
      margin-right: 0;
      margin-bottom: $gap16;
    }
  }

  @include media-tablet {
    flex-direction: column;
  }
}

.partnerCard {
  position: relative;
  background: $main-placeholder-bg;
  border-radius: $_border-radius-common;
  width: calc(100% / 3);
  padding-top: 15%;
  height: 0;

  @include media-tablet {
    width: 100%;
    padding-top: 25%;
  }

  > span {
    position: absolute !important;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70%;
  }
}
