@import "@styles/main.scss";
.root {
  letter-spacing: 0;
}

.level-1 {
  font-size: 42px;
  line-height: 55px;

  @include media-tablet {
    font-size: 32px;
    line-height: 38px;
  }
}

.level-2 {
  font-style: normal;
  font-size: 22px;
  line-height: 31px;

  @include media-tablet {
    font-size: 22px;
    line-height: 26px;
  }
}

.level-3 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

.level-4 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

//TODO level-5 and level-6 will be removed
.level-5 {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0;
}

.level-6 {
  font-size: 18px;
  line-height: 25px;
}

.weight-medium {
  font-weight: 400;
}

.weight-bold {
  font-weight: 700;
}

.muted {
  opacity: 0.5;
}

.fontGraphik {
  font-family: 'Graphik', sans-serif;
}
