@import "@styles/main.scss";
.root {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: fit-content;

  &.isError {
    .checkBlock {
      border-color: $color-error;
    }

    .text {
      color: $color-error;
    }
  }

  input {
    display: none;
  }

  .checkBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: $gap16;
    border: 2px solid $color-input-border;
    box-sizing: border-box;

    & > svg {
      display: none;
    }

    &.size-medium {
      width: 16px;
      height: 16px;
    }

    &.size-large {
      width: 24px;
      height: 24px;
    }

    &.variant-round {
      border-radius: 50%;
    }

    &.variant-square {
      border-radius: $_border-radius-small;
    }
  }

  &:hover .checkBlock {
    opacity: 0.75;
  }

  input:checked {
    & + .checkBlock {
      border: none;
      overflow: hidden;

      & > svg {
        display: block;
        color: $color-primary-redesign;
      }
    }

    & + .checkBlock.variant-round {
      background-color: $color-primary-redesign;
    }
  }

  input:disabled {
    & + .checkBlock {
      cursor: not-allowed;
    }

    & + .checkBlock + .text {
      cursor: not-allowed;
    }
  }
}
