@import "@styles/main.scss";
.root {
  padding: $gap44 * 2 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include media-tablet {
    flex-flow: column wrap;
    padding: $gap32 0;
  }
}

.info {
  margin-right: 5%;
  display: flex;
  flex-flow: column nowrap;
  order: 1;

  @include media-tablet {
    order: 2;
    margin-right: 0;
    flex-flow: row wrap;
    padding-top: $gap24;
    margin-top: $gap12;
    border-top: $main-border;
  }
}

.navItems {
  order: 2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  min-width: 65%;

  @include media-tablet {
    order: 1;
    flex-flow: column nowrap;
  }
}

.logo {
  margin-bottom: $gap40;
  max-width: 246px;

  a {
    cursor: pointer;
  }

  @include media-tablet {
    display: none;
  }
}

.links {
  margin-bottom: $gap12;
}

.brand {
  margin-top: $gap24;
  padding-top: $gap24;
  border-top: $main-border;
  width: 100%;
}

.socialLinks {
  @include media-tablet {
    width: 100%;
    flex-basis: 100%;
  }
}