@import "@styles/main.scss";
$_suggestions-menu-max-height: 400px;

.root {
  background-color: $header-full-bg;
  color: $header-full-color;
  height: $_header-height;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 10;
  top: 0;

  @include media-tablet {
    padding-top: $gap8;
    color: $header-color;
    background: $header-bg;
    height: auto;
  }
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;

  @include media-tablet {
    flex-flow: row wrap;
    width: 100%;
  }
}

.logo {
  order: 0;
  align-self: center;
  display: block;

  @include media-tablet {
    display: none;
  }

  &.logoMobile {
    display: none;

    @include media-tablet {
      display: block;
    }
  }
}

.linksList {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  order: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-tablet {
    @include container-tablet-full-width();
    margin-top: $gap8;
    order: 3;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: $gap16;
    border-bottom: $header-links-border;
  }

  li {
    padding: 0 $gap20;
    height: $_menu-item-height;
    line-height: $_menu-item-height;
    margin: 0 $gap4;
    border-radius: calc($_menu-item-height / 2);

    &:first-child {
      margin-left: 0;
    }

    @include hover {
      background-color: $header-full-color;
      color: $header-full-bg;
      cursor: pointer;

      @include media-tablet {
        background-color: $header-hover-bg;
      }
    }

    @media screen and (max-width: 1350px) {
      padding: 0 10px;
    }

    @include media-tablet {
      border: $header-links-border;
    }

    &.active {
      background-color: $header-full-color;
      color: $header-full-bg;

      @include media-tablet {
        color: $header-full-color;
        background-color: $header-full-bg;
      }
    }
  }
}

.iconsList {
  display: flex;
  align-items: center;
  order: 3;

  @include media-tablet {
    order: 2;
    max-width: 50%;
    padding: 0;
  }

  li {
    width: $_header-icon-item-size;
    height: $_header-icon-item-size;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:not(:last-child) {
      margin-right: $gap8;
    }

    @include media-tablet {
      width: $_header-icon-item-size-mobile;
      height: $_header-icon-item-size-mobile;
    }

    svg {
      width: $_header-icon-size;
      height: $_header-icon-size;

      path {
        fill: $header-full-color;
      }

      @include media-tablet {
        width: $_header-icon-size-mobile;
        height: $_header-icon-size-mobile;

        path {
          fill: $header-icons-color;
        }
      }
    }

    @include hover {
      cursor: pointer;
    }

    &.authorized {
      padding-right: 0;
    }
  }
}

.search {
  position: relative;
  color: $header-search-color;
  margin-left: $gap20;
  order: 2;

  &.searchMobile {
    display: none;

    @include media-tablet {
      display: block;
      order: 4;
      width: 100%;
      margin: $gap16 0;

      .searchInput {
        margin-right: 0;
      }
    }
  }
}

.searchDesktop {
  display: flex;

  @include media-tablet {
    display: none;
  }
}

.searchInput {
  border-radius: $_border-radius-round;
  margin-right: $gap12;
  padding-right: $gap44;

  &:focus:not(:disabled),
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border: 1px solid $color-input-border;
    padding: $gap16;
    padding-right: $gap44;
  }

  .searchMobile & {
    @include media-tablet {
      border: none;
      background: $main-placeholder-bg;
      padding-right: $gap16;
      padding-left: $gap44;

      &:focus:not(:disabled),
      &:hover:not(:disabled),
      &:active:not(:disabled) {
        padding: $gap16;
        padding-left: $gap44;
      }
    }
  }
}

.searchIcon {
  position: absolute;
  right: $gap20;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%);

  .searchMobile & {
    @include media-tablet {
      left: $gap16;
      right: auto;
      line-height: 1;

      svg {
        width: $gap16;
      }
    }
  }
}

ul.suggestionsMenu {
  top: calc($_hero-search-form-padding * 2 + $_input-height - $gap16);
  grid-column-end: 3;
  max-height: $_suggestions-menu-max-height;
  overflow-y: auto;

  @media (max-width: $demo-screen-width) {
    grid-column-end: 4;
    width: calc(100% + $_hero-search-form-padding);
  }
}

.basketCount {
  position: absolute;
  display: inline-block;
  font-family: $default-font;
  font-size: 11px;
  color: $basket-count-color;
  background-color: $basket-count-bg;
  min-width: $_basket-count-width;
  height: $_basket-count-width;
  line-height: $_basket-count-width;
  border-radius: calc($_basket-count-width / 2);
  text-align: center;
  right: $gap4;
  bottom: $gap2;
  box-sizing: content-box;

  @include media-tablet {
    transform: scale(0.8);
    right: 0;
    bottom: -$gap2;
  }
}
