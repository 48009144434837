@import "@styles/main.scss";
@import './src/styles/variables';
@import './src/styles/mixins';

.list {
  display: flex;
  margin-bottom: $gap24;
  padding: 4px;
  border: 1px solid $color-secondary-redesign;
  border-radius: 10px;

  &.full {
    // background: $color-athens-gray;
    border-radius: 10px;
    margin-bottom: $gap16;
  }

  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  margin-right: $gap4;

  &:last-child {
    margin-right: 0;
  }

  &.full {
    width: 100%;
  }
}

// .primary {
//   padding: $gap8 $gap16;
//   border-radius: 12px;
//   display: flex;
//   align-items: center;
//   height: 36px;
//   transition: background-color $transitionTime;

//   &.active {
//     color: $color-white;
//     background-color: $color-primary-redesign;
//     cursor: default;

//     @include hover {
//       &:not(:disabled) {
//         background-color: rgba(90, 172, 129, 0.24);
//       }
//     }
//   }

//   @include hover {
//     &:not(:disabled) {
//       background-color: $color-geyser;
//     }
//   }
// }

.buttonWide {
  // padding: 0 $gap16;
  border-radius: 8px;
  display: flex;
  align-items: center;
  // height: 36px;
  justify-content: center;
  transition: background-color $transitionTime, border-color $transitionTime;

  &.active {
    color: $color-white;
    background-color: $color-primary-redesign;
    cursor: default;

    @include hover {
      &:not(:disabled) {
        background-color: $color-primary-redesign;
      }
    }
  }

  @include hover {
    &:not(:disabled) {
      background-color: $color-secondary-redesign;
    }
  }
}

// .theme-secondary {
//   padding: $gap8 $gap16 !important;
//   border-radius: 56px !important;
//   display: flex;
//   align-items: center;
//   height: 36px;
//   transition: border-color $transitionTime !important;
//   color: $color-gray !important;
//   border: 1px solid $color-geyser !important;

//   &.active {
//     color: $color-black !important;
//     border-color: $color-black !important;
//     cursor: default;
//   }

//   @include hover {
//     &:not(:disabled) {
//       color: $color-black;
//     }
//   }
// }
