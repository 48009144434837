@import "@styles/main.scss";
$_calendar-modal-width: 410px;

.root {
  position: relative;
}

.input {
  position: relative;

  & input {
    padding-right: $gap32;
  }

  & svg {
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    margin: auto;
    pointer-events: none;
  }
}

.modal {
  background: $color-white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  pointer-events: none;
  opacity: 0;

  transition: opacity $transitionTime;

  position: absolute;
  bottom: 100%;
  margin-bottom: $gap12;
  z-index: 2;
  width: 100%;
  padding: 28px;

  @include media-tablet {
    padding: $gap24 $gap12;
  }

  &.active {
    position: auto;
    opacity: 1;
    pointer-events: all;
  }
}

.dateChooser {
  width: $_calendar-modal-width;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gap16;
}

.button {
  svg {
    width: 15px;
    height: 15px;
  }
}

.row {
  display: grid;
  column-gap: $gap4;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: $gap16;
}

.calendarButton {
  color: $color-brand;
  display: flex;
  align-items: center;

  & svg {
    margin-left: $gap8;
  }

  & span {
    font-size: 14px;
    line-height: 16px;
  }
}

.block {
  margin-bottom: $gap32;

  @include media-tablet {
    margin-bottom: $gap24;
  }
}

.title {
  margin-bottom: $gap16;
}

.tabContent {
  display: grid;
  gap: $gap4;
  grid-template-columns: repeat(5, 1fr);

  @include media-tablet {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
  }
}

.choosedButton {
  background-color: $color-brand;
  color: $color-white;
  border-color: $color-brand;
  pointer-events: none;
}

.dayButton {
  white-space: nowrap;

  & span::first-letter {
    text-transform: capitalize;
  }

  @include media-tablet {
    span {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @include media-mobile {
    white-space: normal;
  }
}

.pureText {
  grid-column-end: span 4;
  min-height: 72px;
  display: flex;
  align-items: center;
}

.loader {
  min-height: 124px;
}

.dateModalTitle {
  margin-bottom: $gap24;
}

.bottomBarContent {
  padding: $gap16;

  > *:first-child {
    margin-top: $gap8;
  }
}

.dateBottomBarTitle {
  margin-bottom: $gap12;
  color: $color-primary-light-redesign;

  svg {
    width: 12px;
    height: 12px;
    margin-right: $gap12;

    path {
      stroke: $color-primary-light-redesign;
    }
  }
}
