@import "@styles/main.scss";
.size-extra-small {
  font-size: 11px;
  line-height: 100%;
}

.size-small {
  font-size: 13px;
  line-height: 100%;
}

.size-medium {
  font-size: 16px;
  line-height: 20px;
}

.size-large {
  font-size: 18px;
  line-height: 25px;

  &.fontGraphik {
    @include media-tablet {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.size-large-plus {
  font-size: 26px;
  line-height: 31px;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

.size-extra-large {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.01em;

  @include media-tablet {
    font-size: 18px;
    line-height: 110%;
  }
}

.weight-medium {
  font-weight: 400;
}

.weight-bold {
  font-weight: 700;
}

.fontGraphik {
  font-family: 'Graphik', sans-serif;
}

.muted {
  color: $muted-text-color;
}

.lineInherit {
  line-height: inherit;
}

.uppercase {
  text-transform: uppercase;
}
